import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export default class MainMenu {
	constructor({
		container,
		trigger,
		openClass,
		animationClass,
		bodyClass,
		onOpen,
		onClose
	} = {}) {
		this.trigger   = document.querySelector(trigger) || document.querySelector('[data-mobile-menu="trigger"]');
		this.container = document.querySelector(container) || document.querySelector('[data-mobile-menu="wrapper"]');

		this.options = {
			openClass     : openClass || 'is-open',
			animationClass: animationClass || 'is-animation',
			bodyClass     : bodyClass || 'is-overflow',
			onOpen        : onOpen || null,
			onClose       : onClose || null
		};

		this._init();
	}

	_init() {
		if (!this.trigger && !this.container) return;

		this.trigger.addEventListener('click', this._click.bind(this));
	}

	_click(e) {
		e.preventDefault();
		if (!this.trigger.classList.contains(this.options.openClass)) {
			this._open();
		} else {
			this._close();
		}

		return false;
	}

	_open() {
		this.trigger.classList.add(this.options.openClass);
		document.body.classList.add(this.options.bodyClass);
		this.container.classList.add(this.options.openClass);
		this.container.classList.add(this.options.animationClass);
		disableBodyScroll(this.container);
	}

	_close() {
		const self = this;
		this.container.classList.remove(this.options.animationClass);
		this.trigger.classList.remove(this.options.openClass);

		this.container.addEventListener('animationend', function handler() {
			document.body.classList.remove(self.options.bodyClass);
			enableBodyScroll(self.container);
			self.container.classList.remove(self.options.openClass);
			self.container.removeEventListener('animationend', handler, false)
		}, false);
	}

	closeMainMenu() {
		this._close();
	}
}
