import DropPanel from './components/panel';
import MainMenu  from './components/mainMenu';
import MoveTo    from 'moveto';
import IMask     from 'imask/esm/imask';
import 'imask/esm/masked/number';
import './components/bouncer.polyfills';


function inputMask(el) {
	if (!el) return;
	return IMask(el, {
		mask: '{+}0 (000) 000-00-00',

		definitions: {
			'#': /[012345679]/
		}
	});
}


// Init
function init() {
	function moveToScroll() {
		const moveTo = new MoveTo({
			tolerance: 100
		});

		const moveClass = document.querySelectorAll('[data-scroll]');
		moveClass.forEach(function (item) {
			moveTo.registerTrigger(item);
			item.addEventListener('click', () => mainMenu.closeMainMenu())
		});
	}

	new DropPanel();
	const mainMenu = new MainMenu();
	inputMask(document.querySelector('#tel'));
	moveToScroll();
}


document.addEventListener('DOMContentLoaded', function (event) {
	init();
});

