export default class DropPanel {
	constructor({
		button,
		container,
		classOpen,
		onOpen,
		onClose
	} = {}) {
		this.button                = button || '[data-dropdown="button"]';
		this.clickedOutside        = this._clickedOutside.bind(this);
		this.clickedOutsideHandler = this.clickedOutside.bind(this);
		this.options               = {
			container: container || '[data-dropdown="wrapper"]',
			classOpen: classOpen || 'is-open',
			onOpen   : onOpen || null,
			onClose  : onClose || null
		};

		this._init();
	}

	_init() {
		if (!document.querySelector(this.button)) return;

		document.querySelectorAll(this.button).forEach(function (item) {
			item.addEventListener('click', this._click.bind(this));
		}.bind(this))
	}

	_click(e) {
		e.preventDefault();
		const wrapper = e.target.closest(this.options.container);
		if (!wrapper) return;

		if (!wrapper.classList.contains(this.options.classOpen)) {
			this._open(wrapper);
		} else {
			this._close();
		}

		return false;
	}

	_clickedOutside() {
		const panels = document.querySelectorAll(`${this.options.container}.${this.options.classOpen}`);
		[].forEach.call(panels, el => !el.contains(event.target) && this._close());
	}

	_open(wrapper) {
		wrapper.classList.add(this.options.classOpen);

		if (this.options.onOpen) {
			this.options.onOpen();
		}
		window.addEventListener('click', this.clickedOutsideHandler.bind(this));
	}

	_close() {
		const panels = document.querySelectorAll(`${this.options.container}.${this.options.classOpen}`);
		[].forEach.call(panels, el => el.classList.remove(this.options.classOpen));
		if (this.options.onClose) {
			this.options.onClose();
		}
		window.removeEventListener('click', this.clickedOutsideHandler.bind(this));
	}
}

